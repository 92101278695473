/* ======================================================================== */
/* 12. header */
/* ======================================================================== */
var Header = function () {

	var
		$overlay = $('.header__wrapper-overlay-menu'),
		$menuLinks = $('.overlay-menu > li > a .overlay-menu__item-wrapper'),
		$submenu = $('.overlay-sub-menu'),
		$submenuButton = $('.js-submenu-back'),
		$submenuLinks = $submenu.find('> li > a .overlay-menu__item-wrapper');

	setOverlayMenu();
	stickHeader();

	function setOverlayMenu() {

		TweenMax.set([$overlay, $menuLinks, $submenuLinks], {
			y: '100%'
		});

		TweenMax.set([$submenu, $submenuButton], {
			autoAlpha: 0,
			y: '10px'
		});

	}

	this.closeOverlayMenu = function () {

		var tl = new TimelineMax();

		tl.timeScale(2);

		tl
			.to([$menuLinks, $submenuLinks], 0.6, {
				y: '-100%',
				ease: Power4.easeIn
			})
			.to($submenuButton, 0.6, {
				y: '-10px',
				autoAlpha: 0
			})
			.to($overlay, 1, {
				y: '-100%',
				ease: Expo.easeInOut
			})
			.add(function () {
				setOverlayMenu();
			});

	};

	this.openOverlayMenu = function () {

		var tl = new TimelineMax();

		tl
			.to($overlay, 1, {
				y: '0%',
				ease: Expo.easeInOut,
			}).staggerTo($menuLinks, 0.6, {
				y: '0%',
				ease: Power4.easeOut,
			}, .05, '-=0.3');

	};

	function stickHeader() {

		var $header = $('.js-header-sticky');

		new $.ScrollMagic.Scene({
				offset: '1px',
			})
			.setPin($header, {
				pushFollowers: false
			})
			.setClassToggle($header, 'header_sticky')
			.addTo(SMController);

	}


}
