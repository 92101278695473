/* ======================================================================== */
/* 23. sectionSteps */
/* ======================================================================== */
var SectionSteps = function () {

	var $target = $('.section-steps[data-os-animation] .section-steps__step'),
		$heading = $target.find('.section-steps__content h2'),
		$text = $target.find('.section-steps__content p'),
		$headline = $target.find('.section-steps__headline'),
		$number = $target.find('.section-steps__number'),
		splitDescr = splitLines($text),
		splitHeading = splitLines($heading);

	prepare();

	function prepare() {

		if (!$target.length) {
			return;
		}


		setLines(splitHeading.words);
		setLines(splitDescr.lines);

		TweenMax.set($headline, {
			scaleX: 0,
			transformOrigin: 'left center'
		});

		TweenMax.set($number, {
			autoAlpha: 0,
			y: '30px'
		});

	}

	this.animate = function () {

		if (!$target.length) {
			return;
		}

		$target.each(function () {

			var $el = $(this),
				$elNumber = $el.find($number),
				$elHeadline = $el.find($headline),
				elDescr = $el.find(splitDescr.lines),
				elHeading = $el.find(splitHeading.words),
				tl = new TimelineMax();

			tl
				.to($elNumber, 0.6, {
					autoAlpha: 1,
					y: '0px',
					ease: Power4.easeOut
				})
				.add(animateLines(elHeading), '-=0.4')
				.add(animateLines(elDescr, 1, 0.1), '-=0.4')
				.to($elHeadline, 0.6, {
					scale: 1,
					ease: Power4.easeOut
				}, '-=0.8');

			createOSScene($el, tl);

		})

	}

}
