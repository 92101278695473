/* ======================================================================== */
/* 18. sectionFullscreen */
/* ======================================================================== */
var SectionFullscreen4 = function () {

	var $target = $('.section-fullscreen_4[data-os-animation]'),
		tl = new TimelineMax(),
		$headline = $target.find('.slider-fullscreen4__slide-headline'),
		$heading = $target.find('.slider-fullscreen4__slide-header h2'),
		splitHeading = splitLines($heading);

	prepare();

	function prepare() {

		if (!$target.length) {
			return;
		}

		setLines(splitHeading.words);

		TweenMax.set($headline, {
			scaleX: 0,
			transformOrigin: 'center center',
		});

	}

	this.animate = function () {

		if (!$target.length) {
			return;
		}

		tl
			.staggerTo($headline, 0.6, {
				scaleX: 1,
				ease: Expo.easeInOut
			}, 0.05)
			.add(animateLines(splitHeading.words), '-=0.6');

	}

}

/* ======================================================================== */
/* sectionFullscreen1 */
/* ======================================================================== */
var SectionFullscreen1 = function () {

	var $target = $('.section-fullscreen_1[data-os-animation]'),
		tl = new TimelineMax(),
		$bg = $target.find('.section-fullscreen__inner-bg'),
		$headline = $target.find('.slider-fullscreen__slide-headline'),
		$heading = $target.find('.slider-fullscreen__slide-header h2'),
		$description = $target.find('.slider-fullscreen__slide-header p'),
		$button = $target.find('.slider-fullscreen__slide-wrapper-button'),
		$img = $target.find('.overflow__content'),
		$curtain = $target.find('.overflow__curtain'),
		splitHeading = splitLines($heading),
		splitDescription = splitLines($description);

	prepare();

	function prepare() {

		if (!$target.length) {
			return;
		}

		setLines(splitHeading.words);
		setLines(splitDescription.lines);

		TweenMax.set($headline, {
			scaleX: 0,
			transformOrigin: 'left center',
		});

		TweenMax.set($bg, {
			scaleY: 0,
			transformOrigin: 'bottom center'
		});

		TweenMax.set($img, {
			scale: 1.1,
			autoAlpha: 0,
		});

		TweenMax.set($button, {
			y: '10px',
			autoAlpha: 0
		});

		TweenMax.set($curtain, {
			y: '100%',
		});

	}

	this.animate = function () {

		if (!$target.length) {
			return;
		}

		tl
			.staggerTo($bg, 0.6, {
				scaleY: 1,
				ease: Expo.easeInOut
			}, 0.05)
			.to($curtain, 0.3, {
				y: '0%',
				ease: Expo.easeInOut,
			}, '-=0.6')
			.set($img, {
				autoAlpha: 1
			}).to($img, 0.6, {
				scale: 1,
				ease: Power4.easeOut
			})
			.to($curtain, 0.3, {
				y: '-102%',
				ease: Expo.easeInOut,
			}, '-=0.6')
			.to($headline, 0.6, {
				scaleX: 1,
				ease: Expo.easeInOut
			}, '-=1')
			.add(animateLines(splitHeading.words), '-=0.6')
			.to($button, 0.6, {
				autoAlpha: 1,
				y: '0px'
			}, '-=0.6')
			.add(animateLines(splitDescription.lines, 1, 0.1), '-=0.6')

	}

}
