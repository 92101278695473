/* ======================================================================== */
/* 6. figurePortfolio */
/* ======================================================================== */
var FigurePortfolio = function () {

	var $target = $('.figure-portfolio[data-os-animation]'),
		$img = $target.find('.overflow__content'),
		$curtain = $target.find('.overflow__curtain'),
		$heading = $target.find('.figure-portfolio__header h2'),
		$headline = $target.find('.figure-portfolio__headline'),
		$info = $target.find('.figure-portfolio__info'),
		splitHeading = splitLines($heading),
		splitInfo = splitLines($info);

	prepare();

	function prepare() {

		if (!$target.length) {
			return;
		}

		setLines(splitHeading.words);
		setLines(splitInfo.words);

		TweenMax.set($headline, {
			scaleX: 0,
			transformOrigin: 'left center'
		});

		setCurtainImg($curtain, $img);

	}

	this.animate = function () {

		if (!$target.length) {
			return;
		}

		$target.each(function () {

			var $el = $(this),
				tl = new TimelineMax(),
				$elLink = $el.find('.figure-portfolio__link'),
				$elImg = $el.find($img),
				$elCurtain = $el.find($curtain),
				$elHeading = $el.find($heading),
				$elHeadline = $el.find($headline),
				elSplitInfo = $el.find(splitInfo.words),
				elSplitHeading = $el.find(splitHeading.words);

			tl
				.add(animateCurtainImg($elCurtain, $elImg))
				.to($elHeadline, 0.6, {
					scaleX: 1,
					ease: Power4.easeOut
				}, '-=1')
				.add(animateLines(elSplitInfo), '-=0.8')
				.add(animateLines(elSplitHeading), '-=0.8');

			createOSScene($el, tl);

			$elLink
				.on('mouseenter touchstart', function () {

					TweenMax.to($elImg, 0.3, {
						scale: 1.1,
						ease: Power3.easeInOut,
					});

					TweenMax.to($elHeadline, 0.3, {
						scaleX: 0.8,
						ease: Power3.easeInOut,
						transformOrigin: 'right center'
					});

					TweenMax.to($elHeading, 0.3, {
						x: '10px',
						ease: Power3.easeInOut,
					});

				})
				.on('mouseleave touchend', function () {

					TweenMax.to($elImg, 0.3, {
						scale: 1,
						ease: Power2.easeInOut,
					});

					TweenMax.to($elHeadline, 0.3, {
						scaleX: 1,
						ease: Power2.easeInOut,
						transformOrigin: 'right center'
					});

					TweenMax.to($elHeading, 0.3, {
						x: '0px',
						ease: Power2.easeInOut,
					});

				});

		});

	}


}
