/* ======================================================================== */
/* 8. figureService */
/* ======================================================================== */
var FigureService = function () {

	var $target = $('.figure-service');

	if (!$target.length) {
		return;
	}

	var circle = new Circle(),
		$icons = $target.find('.figure-service__icon'),
		$headlines = $target.find('.figure-service__headline'),
		$numbers = $target.find('.figure-service__number'),
		$texts = $target.find('.figure-service__header p'),
		splitDescr = new SplitText($texts, {
			type: 'lines',
			linesClass: 'split-line',
		});

	setLines(splitDescr.lines);

	$target.each(function () {

		var $el = $(this),
			$elIcon = $el.find($icons),
			$elHeadline = $el.find($headlines),
			$elNumber = $el.find($numbers),
			tl = new TimelineMax(),
			elDescr = $el.find(splitDescr.lines);

		circle.animate($el);

		$el
			.on('mouseenter touchstart', function () {

				tl
					.clear()
					.to($elHeadline, 0.6, {
						scaleX: 2,
						ease: Power4.easeOut
					})
					.to($elNumber, 0.3, {
						y: '-50px',
						autoAlpha: 0
					}, '-=0.6')
					.to($elIcon, 0.6, {
						y: '-50px',
						ease: Power4.easeOut
					}, '-=0.6')
					.add(animateLines(elDescr, 0.6, 0.1), '-=0.6');

			})
			.on('mouseleave touchend', function () {

				tl
					.clear()
					.to($elHeadline, 0.3, {
						scaleX: 1
					})
					.to($elNumber, 0.3, {
						y: '0px',
						autoAlpha: 1
					}, '-=0.3')
					.to($elIcon, 0.3, {
						y: '0px'
					}, '-=0.3')
					.to(elDescr, 0.3, {
						y: '100%',
						autoAlpha: 0
					}, '-=0.3');

			});

	});


}
