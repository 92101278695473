/* ======================================================================== */
/* 28. sliderServices */
/* ======================================================================== */
var SliderServices = function () {

	var $target = $('.slider-services[data-os-animation]'),
		tl = new TimelineMax(),
		$headline = $target.find('.figure-service__headline'),
		$heading = $target.find('.figure-service__header h3'),
		$counters = $target.find('.figure-service__number'),
		$icons = $target.find('.figure-service__icon'),
		splitHeading = splitLines($heading);

	prepare();
	createSlider();

	function prepare() {

		if (!$target.length) {
			return;
		}

		setLines(splitHeading.words);

		TweenMax.set($headline, {
			scaleX: 0,
			transformOrigin: 'center center',
		});

		TweenMax.set([$counters, $icons], {
			y: '30px',
			autoAlpha: 0
		});

	}

	this.animate = function () {

		if (!$target.length) {
			return;
		}

		tl
			.staggerTo($headline, 0.6, {
				scaleX: 1,
				ease: Expo.easeInOut
			}, 0.05)
			.add(animateLines(splitHeading.words), '-=0.6')
			.staggerTo($counters, 0.6, {
				y: '0px',
				autoAlpha: 1
			}, 0.1, '-=0.6')
			.staggerTo($icons, 0.6, {
				y: '0px',
				autoAlpha: 1
			}, 0.1, '-=0.6');

		createOSScene($target, tl);

	}

	function createSlider() {

		if (!$('.js-slider-services').length) {
			return;
		}

		var slider = new Swiper('.js-slider-services', {
			slidesPerView: 4,
			speed: 800,
			autoplay: {
				delay: 5000
			},
			pagination: {
				el: '.js-slider-services-progress',
				type: 'progressbar',
				progressbarFillClass: 'slider__progressbar-fill',
				renderProgressbar: function (progressbarFillClass) {
					return '<div class="slider__progressbar"><div class="' + progressbarFillClass + '"></div></div>'
				}
			},
			navigation: {
				prevEl: '.js-slider-services__arrow-left',
				nextEl: '.js-slider-services__arrow-right',
			},
			breakpoints: {
				1400: {
					slidesPerView: 3
				},
				991: {
					slidesPerView: 2
				},
				576: {
					slidesPerView: 1
				},
			}
		});

		renderSliderCounter(
			slider,
			'.js-slider-services-counter-current',
			'',
			'.js-slider-services-counter-total'
		);

	}

}
