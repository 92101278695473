/* ======================================================================== */
/* 5. figureFeature */
/* ======================================================================== */
var FigureFeature = function () {

	var $elements = $('.figure-feature');

	if (!$elements.length) {
		return;
	}

	var circle = new Circle();

	$elements.each(function () {
		circle.animate($(this));
	});

}
