/* ======================================================================== */
/* 20. sectionIntro */
/* ======================================================================== */
var SectionIntro = function () {

	var $target = $('.section-intro[data-os-animation]'),
		tl = new TimelineMax(),
		$heading = $target.find('h1'),
		$highlight = $heading.find('.highlight__bg'),
		splitHeading = splitLines($heading);

	prepare();

	function prepare() {

		if (!$target.length) {
			return;
		}

		setLines(splitHeading.words);

		TweenMax.set($target, {
			scaleY: 0,
			transformOrigin: 'bottom center'
		});

		TweenMax.set($highlight, {
			x: '-100%',
			y: '98%'
		})

	}

	this.animate = function () {

		if (!$target.length) {
			return;
		}

		tl
			.to($target, 1, {
				scaleY: 1,
				ease: Expo.easeInOut
			})
			.add(animateLines(splitHeading.words), '-=0.4')
			.to($highlight, 0.6, {
				x: '0%',
				ease: Expo.easeInOut
			}, '-=0.4')
			.to($highlight, 0.6, {
				y: '0%',
				ease: Expo.easeInOut
			});

		// createOSScene($target, tl);

	}

}
