/* ======================================================================== */
/* 14. preloader */
/* ======================================================================== */
var Preloader = function (callback) {

	var
		$preloader = $('.preloader'),
		$curtain = $preloader.find('.preloader__curtain'),
		$logo = $preloader.find('.preloader__logo'),
		$rect = $logo.find('.rect'),
		tl = new TimelineMax();

	load();

	$('body').imagesLoaded().always({
		background: true
	}, function () {

		if (!$preloader.length) {
			callback();
			return;
		}

		finish();
	});

	function finish() {

		tl
			.clear()
			.to($rect, 2, {
				drawSVG: true,
				ease: Expo.easeInOut
			})
			.to($logo, 0.3, {
				autoAlpha: 0,
			}, '-=0.3')
			.staggerTo($curtain, 1, {
				y: '-100%',
				ease: Expo.easeInOut
			}, 0.05, '-=0.3')
			.set($preloader, {
				autoAlpha: 0
			})
			.add(function () {
				callback();
			}, '-=0.4');

	}

	function load() {

		tl.fromTo($rect, 15, {
			drawSVG: 0,
			stroke: '#b68c70',
			ease: SlowMo.ease.config(0.7, 0.7, false)
		}, {
			drawSVG: true,
		});

	}

	this.curtainsUp = function () {

		tl
			.staggerTo($curtain, 1, {
				y: '-100%',
				ease: Expo.easeInOut
			}, 0.05)
			.set($preloader, {
				autoAlpha: 0
			});

	}

	this.curtainsDown = function () {

		tl
			.set($preloader, {
				autoAlpha: 1
			})
			.staggerTo($curtain, 1, {
				y: '0%',
				ease: Expo.easeInOut
			}, 0.05)
			.set($rect, {
				drawSVG: 0
			})
			.to($logo, 0.6, {
				autoAlpha: 1,
			});

	}

}
