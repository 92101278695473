/* ======================================================================== */
/* 3. button */
/* ======================================================================== */
var Button = function () {

	$('.button-square').each(function () {

		var
			$el = $(this),
			$rect = $el.find('.rect');

		TweenMax.set($rect, {

			drawSVG: 0,
			stroke: '#b68c70',

		});

		$el.on('mouseenter touchstart', function () {

			TweenMax.to($rect, 0.6, {
				drawSVG: true,
				ease: Power4.easeInOut
			});

		}).on('mouseleave touchend', function () {

			TweenMax.to($rect, 0.6, {
				drawSVG: false,
				ease: Power4.easeInOut
			});

		});

	});

}
