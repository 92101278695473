/* ======================================================================== */
/* 1. animations */
/* ======================================================================== */
function createOSScene($el, tl) {

	new $.ScrollMagic.Scene({
			triggerElement: $el,
			triggerHook: SMSceneTriggerHook,
			reverse: SMSceneReverse
		})
		.setTween(tl)
		.addTo(SMController);

}

function animateCurtainImg($curtain, $img) {

	var tl = new TimelineMax();

	return tl.to($curtain, 0.3, {
		x: '0%',
		ease: Expo.easeInOut,
	}).to($curtain, 0.4, {
		y: '0%',
		ease: Expo.easeInOut,
	}).set($img, {
		autoAlpha: 1
	}).to($img, 1, {
		scale: 1,
		ease: Power4.easeOut
	}).to($curtain, 0.3, {
		y: '102%',
		ease: Expo.easeInOut,
	}, '-=1');

}

function animateCurtainContent($curtain, $content) {

	var tl = new TimelineMax();

	return tl.to($curtain, 0.3, {
		x: '0%',
		ease: Expo.easeInOut,
	}).to($curtain, 0.4, {
		y: '0%',
		ease: Expo.easeInOut,
	}).set($content, {
		autoAlpha: 1
	}).to($curtain, 0.3, {
		y: '102%',
		ease: Expo.easeInOut,
	});

}

function setCurtainImg($curtain, $img) {

	TweenMax.set($img, {
		scale: 1.1,
		autoAlpha: 0,
	});

	TweenMax.set($curtain, {
		y: '-99%',
		x: '-100%'
	});

}

function setCurtainContent($curtain, $content) {

	TweenMax.set($content, {
		autoAlpha: 0,
	});

	TweenMax.set($curtain, {
		y: '-99%',
		x: '-100%'
	});

}
