/* ======================================================================== */
/* 21. sectionLogos */
/* ======================================================================== */
var SectionLogos = function () {

	var $target = $('.section-logos[data-os-animation] .section-logos__wrapper-items'),
		tl = new TimelineMax(),
		$logos = $target.find('.section-logos__item');

	prepare();

	function prepare() {

		if (!$target.length) {
			return;
		}

		TweenMax.set($logos, {
			y: '30px',
			autoAlpha: 0
		});

	}

	this.animate = function () {

		if (!$target.length) {
			return;
		}

		tl
			.staggerTo($logos, 1, {
				autoAlpha: 1,
				y: '0px',
				ease: Power4.easeOut
			}, 0.1);

		createOSScene($target, tl);

	}

}
