/* ======================================================================== */
/* 15. sectionCTA */
/* ======================================================================== */
var SectionCTA = function () {

	var $target = $('.section-cta[data-os-animation]'),
		$header = $target.find('.section-cta__header'),
		$headline = $target.find('.section-cta__headline'),
		$heading = $header.find('h2, h4'),
		$button = $target.find('.section-cta__wrapper-button'),
		splitHeading = splitLines($heading);

	prepare();

	function prepare() {

		if (!$target.length) {
			return;
		}

		setLines(splitHeading.lines);

		TweenMax.set($button, {
			autoAlpha: 0,
			y: '30px'
		});

		TweenMax.set($headline, {
			scaleX: 0,
			transformOrigin: 'left center'
		});
	}

	this.animate = function () {

		if (!$target.length) {
			return;
		}

		$target.each(function () {

			var $el = $(this),
				elLines = $el.find(splitHeading.lines),
				$elHeader = $el.find($header),
				tl = new TimelineMax()

			tl
				.add(animateLines(elLines, 1, 0.1))
				.to($button, 0.6, {
					autoAlpha: 1,
					y: '0px'
				}, '-=0.8')
				.to($headline, 0.6, {
					scaleX: 1,
					ease: Expo.easeInOut
				}, '-=0.6');

			createOSScene($elHeader, tl);

		});

	}

}
