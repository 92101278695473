/* ======================================================================== */
/* 17. sectionHeader */
/* ======================================================================== */
var SectionHeader = function () {

	var $target = $('.section-header[data-os-animation]'),
		$square = $target.find('.section-header__square'),
		$label = $target.find('.section-header__label span'),
		$heading = $target.find('h2'),
		splitHeading = splitLines($heading),
		splitLabel = splitLines($label);

	prepare();

	function prepare() {

		if (!$target.length) {
			return;
		}

		setLines([splitHeading.lines, splitLabel.lines]);

		TweenMax.set($square, {
			transformOrigin: 'left center',
			scaleX: 0
		});

	}

	this.animate = function () {

		if (!$target.length) {
			return;
		}

		$target.each(function () {

			var
				$el = $(this),
				tl = new TimelineMax(),
				$elSquare = $el.find($square),
				$elLabel = $el.find($label),
				$elHeading = $el.find($heading),
				elSplitHeading = $el.find(splitHeading.lines),
				elSplitLabel = $el.find(splitLabel.lines);

			tl
				.to($elSquare, 0.6, {
					scaleX: 1,
					ease: Power4.easeOut
				})
				.add(animateLines(elSplitLabel, 1, 0.1), '-=1')
				.add(animateLines(elSplitHeading, 1, 0.1), '-=0.8');

			createOSScene($el, tl);

		});

	}

}
