/* ======================================================================== */
/* 31. splitText */
/* ======================================================================== */
function splitLines($el) {

	if (!($el).length) {
		return false;
	}

	return new SplitText($el, {
		type: 'words, lines',
		linesClass: 'split-line',
		wordsClass: 'split-word'
	});

};


function setLines(el) {

	if (!$(el).length) {
		return false;
	}

	return TweenMax.set(el, {
		y: '150%',
		autoAlpha: 0
	});

}

function animateLines(el, customDuration, customStagger) {

	if (!$(el).length) {
		return false;
	}

	var
		duration = customDuration ? customDuration : 0.6,
		stagger = customStagger ? customStagger : 0.03;

	return TweenMax.staggerTo(el, duration, {
		y: '0%',
		autoAlpha: 1,
		ease: Power4.easeOut
	}, stagger);

}

function hideLines(el, customDuration, customStagger) {

	if (!$(el).length) {
		return false;
	}

	var
		duration = customDuration ? customDuration : 0.6,
		stagger = customStagger ? customStagger : 0.03;

	return TweenMax.staggerTo(el, duration, {
		y: '150%',
		autoAlpha: 0,
		ease: Power4.easeIn
	}, stagger);

}
