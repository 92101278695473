/* ======================================================================== */
/* 4. circle */
/* ======================================================================== */
var Circle = function () {

	this.animate = function ($el) {

		var $circle = $el.find('.circle');

		if (!$circle.length) {
			return;
		}

		TweenMax.set($circle, {

			drawSVG: 0,
			stroke: '#b68c70',

		});

		$el.on('mouseenter touchstart', function () {

			TweenMax.to($circle, 0.6, {
				drawSVG: true,
				ease: Power4.easeInOut
			});

		}).on('mouseleave touchend', function () {

			TweenMax.to($circle, 0.6, {
				drawSVG: false,
				ease: Power4.easeInOut
			});

		});

	}

}
